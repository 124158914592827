define('lazarus/pods/components/questions/question-numeric/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['question', "question-multiple-choice"]
	});
});