define('lazarus/risk_scales', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {

		'1': { kinetic_cde_score: 1, kinetic_cde_label: 'Low', tea: 'Activity approved by Commander' },
		'2': { kinetic_cde_score: 2, kinetic_cde_label: 'Low', tea: 'Activity approved by Commander' },
		'3': { kinetic_cde_score: 3, kinetic_cde_label: 'Low', tea: 'Activity approved by Commander' },
		'4': { kinetic_cde_score: 3, kinetic_cde_label: 'Low', tea: 'Activity approved by Commander' },
		'5': { kinetic_cde_score: 4, kinetic_cde_label: 'Low', tea: 'Activity approved by Commander' },
		'6': { kinetic_cde_score: 4, kinetic_cde_label: 'Low', tea: 'Activity approved by Commander' },
		'7': { kinetic_cde_score: 5, kinetic_cde_label: 'Low', tea: 'Activity approved by Commander' },
		'8': { kinetic_cde_score: 5, kinetic_cde_label: 'High', tea: 'Approval by Higher Comd required' },
		'9': { kinetic_cde_score: 5, kinetic_cde_label: 'High', tea: 'Approval by Higher Comd required' },
		'10': { kinetic_cde_score: 5, kinetic_cde_label: 'High', tea: 'Approval by Higher Comd required' }
	};
});