define('lazarus/models/conop', ['exports', 'ember-pouch/model', 'ember-data'], function (exports, _model, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const {
		attr,
		hasMany,
		belongsTo
	} = _emberData.default;

	exports.default = _model.default.extend({

		target: _emberData.default.belongsTo('target'),

		title: _emberData.default.attr('string'), // added in 1.1.0
		description: _emberData.default.attr('string'), // added in 1.1.0
		modified: _emberData.default.attr('date'),

		desired_effect: _emberData.default.attr('number'), // 1d
		method: _emberData.default.attr('number'), // 1e

		casualty_threshold: _emberData.default.attr('number'), // 2
		expected_casualties: _emberData.default.attr('number'), // 3
		human_shields_in_area: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), // 3.1 (added 1.2.0)

		has_possible_negative_sentiment: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), // 4
		probable_negative_sentiment: _emberData.default.attr('number'), // 5

		activities: _emberData.default.attr({ defaultValue() {
				return [];
			} }), // 6
		activities_other_description: _emberData.default.attr('string'), // added 1.2.0
		activities_non_caf: _emberData.default.attr({ defaultValue() {
				return [];
			} }), // 6a

		activities_description_ogds: _emberData.default.attr('string'), // added 1.2.0,
		activities_description_ios_ngos: _emberData.default.attr('string'), // added 1.2.0,
		activities_description_proxies: _emberData.default.attr('string'), // added 1.2.0,

		activities_risk_non_caf: _emberData.default.attr('number'), // 6b (added 1.2.0)

		degree_difficulty: _emberData.default.attr('number'), // 7
		degree_resources: _emberData.default.attr('number'), // 8

		part_of_io_campaign: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), // 9
		degree_dependence_on_io: _emberData.default.attr('number'), // 10

		tactical_hazards_exist: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), // 11
		tactical_hazards_present_threat: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), // 12
		degree_threat_tactical_hazards: _emberData.default.attr('number'), // 13

		will_employ_mitigations: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), // 14
		degree_likelihood_mitigations_effective: _emberData.default.attr('number'), // 15
		degree_mitigations_effective: _emberData.default.attr('number'), // 16

		macro_analysis: _emberData.default.attr({ defaultValue() {
				var pmesii = ['political', 'military', 'economic', 'social', 'information', 'infrastructure', 'physical', 'time'];
				var ascope = ['areas', 'structures', 'capabilities', 'organizations', 'people', 'events'];

				var value = {};
				pmesii.forEach(function (x) {
					ascope.forEach(function (y) {
						value[x + '_' + y] = { has_response: false, score: null, not_applicable: false, description: '' };
					});
				});
				return value;
			}
		}),

		micro_analysis: _emberData.default.attr({ defaultValue() {
				var dimensions = ['cognitive', 'affective', 'motivational'];

				var value = {};
				dimensions.forEach(function (x) {
					value[x] = { has_response: false, score: null, not_applicable: false, description: '' };
				});
				return value;
			}
		}),

		serial_1c: Ember.computed.oneWay('target.can_be_bounded'),
		serial_1d: Ember.computed.oneWay('desired_effect'),
		serial_1e: Ember.computed.oneWay('method'),
		serial_3: Ember.computed('casualty_threshold', 'expected_casualties', function () {
			var ct = this.get('casualty_threshold');
			var ec = this.get('expected_casualties');
			if (ct === null || ec === null) return false;
			if (ec > ct) return 10;
			if (ec == ct) return 5;
			return 1;
		}),
		serial_5: Ember.computed.oneWay('probable_negative_sentiment'),
		serial_6b: Ember.computed.oneWay('activities_risk_non_caf'),
		serial_7: Ember.computed.oneWay('degree_difficulty'),
		serial_8: Ember.computed.oneWay('degree_resources'),
		serial_10: Ember.computed.oneWay('degree_dependence_on_io'),
		serial_13: Ember.computed.oneWay('degree_threat_tactical_hazards'),
		serial_15: Ember.computed.oneWay('degree_likelihood_mitigations_effective'),
		serial_16: Ember.computed.oneWay('degree_mitigations_effective'),

		auto_high_risk: Ember.computed('human_shields_in_area', function () {

			if (this.get('human_shields_in_area')) return true;

			return false;
		}),

		score_step1: Ember.computed('modified', 'target.modified', function () {

			// 1c, 1d, 1e, 3, 5, 6b, 7, 8, 10, 13, 15, 16

			// Every scored item returns a value from 0 to 10
			// Only answers that aren't null are included in the score
			//   So, only questions the user actually answers are factored in.

			// Highest possible score: 10

			var self = this;
			var values = ['1c', '1d', '1e', '3', '5', '6b', '7', '8', '10', '13', '15', '16'].map(function (item) {

				return self.get('serial_' + item);
			}).filter(function (item) {

				if (item === null) return false;
				return true;
			});
			var n = values.length;
			var sum = values.reduce(function (prev, current, list) {
				return prev + current;
			}, 0);
			return sum / n;
		}),

		// HPS: 10
		score_step2a: Ember.computed('modified', 'target.modified', 'macro_analysis', function () {

			// Every dimension is ranked as an even number from 2 to 10
			// It doesn't matter whether an answer is marked as 'not applicable', we still divide by 48

			// The highest possible score is 10, derived as such:
			//    48 (cells) * 10 (in each cell) / 48 (fixed denominator) = 10

			var macro = this.get('macro_analysis');
			var values = [];
			var completed = true;
			for (var key in macro) {
				completed = completed && macro[key].has_response;
				if (macro[key].has_response && !macro[key].not_applicable) values.push(macro[key].score);
			};

			if (!completed) return false;
			var n = values.length;
			var sum = values.reduce(function (prev, current, list) {
				return prev + current;
			}, 0);
			return sum / 48;
		}),

		score_step2b: Ember.computed('modified', 'target.modified', 'micro_analysis', function () {

			// Only dimensions that aren't marked as 'not applicable' are factored in to the mean
			// So, if users only answer one question, that value from 1 - 10 becomes the score

			// Highest possible score is 10

			var micro = this.get('micro_analysis');
			var values = [];
			var completed = true;
			for (var key in micro) {
				completed = completed && micro[key].has_response;
				if (micro[key].has_response && !micro[key].not_applicable) values.push(micro[key].score);
			};
			if (!completed) return false;
			var sum = values.reduce(function (prev, current, list) {
				return prev + current;
			}, 0);
			return sum / values.length;
		}),

		risk_score: Ember.computed('score_step1', 'score_step2a', 'score_step2b', 'auto_high_risk', function () {

			// Highest possible score: 10 
			// Derived as such: (10 + 10 + 10) / 3 

			var step_1 = this.get('score_step1');
			var step_2a = this.get('score_step2a');
			var step_2b = this.get('score_step2b');

			if (step_1 === false || step_2a === false || step_2b === false) return false;

			if (this.get('auto_high_risk')) return 10;

			return Math.round((step_1 + step_2a + step_2b) / 3);
		})

	});
});