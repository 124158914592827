define('lazarus/services/user', ['exports', 'lazarus/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		config: _environment.default,

		store: Ember.inject.service(),

		activeUser: null,
		//activeReplication: null,

		//incompleteReplication: false,	// indicates if the previous user's replication task hasn't completed yet
		//previousUser: null,				// tracks the previous user ID, if incompleteReplication is true
		//previousHash: null,
		//lingeringReplications: {},  	// stores replication tasks that haven't completed yet (e.g. active user was switched, deleted account, etc)

		initialize_active_user() {

			var self = this;
			return self.get('store').queryRecord('user', { filter: { active: true } }).then(function (result) {
				console.log('Result of searching for active users.', result);

				if (result === null) {

					// no default user exists. create one now
					console.log('No users exist yet. Creating the first one');

					return self.get('store').createRecord('user', {

						name: 'Default User',
						active: true

					}).save().then(function (new_user) {

						return new_user;
					});
				} else {

					// there is an existing user, so set the active user accordingly
					console.log('An active user already exists');

					return result;
				}
			}).then(function (user_record) {

				self.set_active_user(user_record);
				return user_record;
			});
		},

		/*
  	Initializes currently active user of the application
  */
		set_active_user(user) {

			console.log('set_active_user', user);

			var self = this;

			return new Ember.RSVP.Promise(function (resolve, reject) {

				// handle the case when the app boots up
				if (self.get('activeUser') === null) {

					// no records exist in the database yet.
					// we will create the first record, and set the local database's replication filter to just that user's records
					self.set('activeUser', user);
					resolve(user);
					return;
				}

				// if the current and new user are identical, return without doing anything
				if (user.get('id') == self.get('activeUser.id')) {
					console.log('Previous and current users are the same. Ignoring this step.');
					resolve(user);
					return;
				}

				// otherwise, deactivate the current user, then set the new user to active and save
				console.log('Switching users now.');

				// They may have an active replication to their remote userdb.
				// We need to save their user ID so we can ensure this replication completes 
				//   (including setting their active status to false) before it's cancelled
				// Otherwise we will have a race condition with the new user's replication replacing the current one
				/*
    if( self.get('activeReplication') !== null ){
    	self.set( 'incompleteReplication', true);
    	self.set( 'previousUser', self.get('activeUser.id') );
    	self.set( 'previousHash', self.get('activeUser.data.hash'));
    }
    */

				self.set('activeUser.active', false);

				self.get('activeUser').save().then(function () {

					console.log('Previous user has been saved as inactive');

					// carry on with the new user's details
					self.set('activeUser', user);
					self.set('activeUser.active', true);

					self.get('activeUser').save().then(function () {

						resolve(self.get('activeUser'));
					});
				});
			}).then(function (data) {

				// The active user has been changed locally. 
				// Once we implement replication to a remote server, check to see if this user has a remote DB yet
				//self.resume_replication();

			});
		}

	} /*
   resume_replication(){
   		var self = this;
   	var data = this.get('activeUser');
   		// ensure an active user exists, in case we arrive here somehow without this condition met
   	if(data === null) return;
   		console.log('Now checking to see whether the active user has a remote database to sync to.');
   	console.log(data.id);
   		// Only replicate if the user has opted into the study
   	if( ! this.get('activeUser.hasGrantedInformedConsent') ) return;
   		self.db.get('_local/sync_established_' + config.environment + '_' + data.id).then(function(doc){
   			console.log('Remote sync has been established.');
   		return data;
   		}, function(err){
   			// no evidence that the remote database has been established yet. begin the transaction to create it
   		console.log('Attempt to create remote database now.', data.data.hash);
   		return fetch( config.remote_sync_endpoint + '/create-account', {
   				method: 'post',
   			body: JSON.stringify( {
   				id: data.id,
   				hash: data.data.hash,
   				environment: config.environment
   			} )
   			}).then( function( response ){
   				// TODO: verify if this is a valid response or if an error was returned. Likelihood is low that it's an error.
   			console.log('Response from server', response);
   				// if the server errors out, don't set the sync_established flag, so the app will keep trying later
   			if(response.status != 200);
   				return data;
   				// set the flag indicating that a remote database has been created and that remote replication has been established
   			return self.db.put({ _id: '_local/sync_established_' + config.environment + '_' + data.id }).then(function(){
   				return data;
   			});
   
   		}).catch( function(err){
   				// We haven't set the preloaded flag, so it will continue to attempt to establish remote replication in the future
   			console.log('Server error. Aborting attempt to resume replication for now.');
   			});
   		}).then(function(data){
   			console.log('Transaction step is complete. Continue with replication.');
   		console.log(data);
   		if(self.get('activeReplication') !==  null){
   			self.get('activeReplication').cancel();
   			self.set('activeReplication', null);
   		}
   		if(self.get('activeAnalyticsReplication') !==  null){
   			self.get('activeAnalyticsReplication').cancel();
   			self.set('activeAnalyticsReplication', null);
   		}
   			var transformedDB = new PouchDB( config.emberPouch.localDb );
   			var replication = transformedDB.replicate.to( config.remote_couch_server + '/userdb-' + String2Hex(data.id), {
   			live: true,
   			retry: true,
   			filter: function (doc) {
   					// filter only the active user's record
   				if(doc._id == 'user_2_' + data.id) return true;
   					// ignore all other user documents
   				if(doc._id.indexOf('user_2_') !== -1) return false;
   					// if the model has a user key,
   				// ensure that only models belonging to the current user are replicated
   				if(doc.data.user){
   					if(doc.data.user == data.id)
   						return true;
   					else
   						return false;
   				}
   					// Replicate any other documents in the database,
   				//   as these are related docs with no identifiable user info
   				// It's okay if these documents appear in more than one user's database
   				//   as they have the same IDs, and will be consolidated in the central DB
   				// CouchDB is great.
   					return true;
   			},
   			auth: {
   				username: data.id,
   				password: data.data.hash
   			}
   			}).on('change', function (info) {
   			console.log("Change detected", info);
   		  // handle change
   		}).on('paused', function (err) {
   		  // replication paused (e.g. replication up to date, user went offline)
   			console.log("Replication paused", err);
   		}).on('active', function () {
   		  // replicate resumed (e.g. new changes replicating, user went back online)
   			console.log("Replication resumed");
   		}).on('denied', function (err) {
   		  // a document failed to replicate (e.g. due to permissions)
   			console.log("Document failed to replicate", err);
   		}).on('complete', function (info) {
   		  // handle complete
   			console.log("Replication complete");
   		}).on('error', function (err) {
   		  // handle error
   			console.log("Replication error: ", err);
   		});
   		
   		self.set('activeReplication', replication);
   			// in case we detected that the previous user might have outstanding docs to replicate, 
   		//   set up a separate replication task for them, and store it in lingeringReplications
   		if(self.get('incompleteReplication')){
   			var previousUser = self.get('previousUser');
   			var previousHash = self.get('previousHash');
   			console.log('about to set a lingering replication', previousUser);
   			self.set('lingeringReplications.' + previousUser, transformedDB.replicate.to( config.remote_couch_server + '/userdb-' + String2Hex(previousUser) ), {
   				live: true,
   				retry: true,
   				filter: function (doc) {
   
   					// filter only the active user's record
   					if(doc._id == 'user_2_' + previousUser) return true;
   
   					// ignore all other user documents
   					if(doc._id.indexOf('user_2_') !== -1) return false;
   
   					// if the model has a user key,
   					// ensure that only models belonging to the current user are replicated
   					if(doc.data.user){
   						if(doc.data.user == previousUser)
   							return true;
   						else
   							return false;
   					}
   
   					return true;
   				},
   				auth: {
   					username: previousUser,
   					password: previousHash,
   				}
   
   			}).on('change', function (info) {
   				console.log("[Lingering] Change detected", info);
   			  // handle change
   			}).on('active', function () {
   			  // replicate resumed (e.g. new changes replicating, user went back online)
   				console.log("[Lingering] Replication resumed");
   			}).on('denied', function (err) {
   			  // a document failed to replicate (e.g. due to permissions)
   				console.log("[Lingering] Document failed to replicate", err);
   			}).on('complete', function (info) {
   			  // handle complete
   				console.log("[Lingering] Replication complete");
   			}).on('error', function (err) {
   			  // handle error
   				console.log("[Lingering] Replication error: ", err);
   			}).on('paused', function (err) {
   			    // If this event is triggered and err is undefined, this replication is up to date.
   			    // This means the user's remaining changes have been sent up to the server
   			    //   and we can remove this replication task from lingeringReplications
   				console.log("[Lingering] Replication paused", err);
   				if(err !== undefined) return;
   					self.get('lingeringReplications.' + previousUser).cancel();
   				self.set('lingeringReplications.' + previousUser, undefined);
   				self.set('previousUser', null);
   				self.set('previousHash', null);
   				self.set('incompleteReplication', false);
   			});
   		}
   		})
   	},*/

	);
});