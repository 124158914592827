define('lazarus/pods/components/questions/question-likert-scale/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['question', "question-likert-scale"],

		low_label: Ember.computed.alias('question.answer.firstObject.label'),
		high_label: Ember.computed.alias('question.answer.lastObject.label'),

		selectedResponse: Ember.computed('question', 'response', function () {
			var response = this.get('question.answer').findBy('value', this.get('response'));
			if (response) return response.label;
			return null;
		})

	});
});