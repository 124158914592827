define('lazarus/models/assessment', ['exports', 'ember-pouch/model', 'ember-data'], function (exports, _model, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const {
		attr,
		hasMany,
		belongsTo
	} = _emberData.default;

	exports.default = _model.default.extend({

		// this value is set once data has been migrated to target and conop models, per version 1.1.0
		archived: _emberData.default.attr('boolean'),

		analyst: _emberData.default.attr('string'),
		name: _emberData.default.attr('string'),
		conops: _emberData.default.attr('string'),
		created: _emberData.default.attr('date', { defaultValue() {
				return new Date();
			} }),
		modified: _emberData.default.attr('date'),

		has_positive_id: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), //1a
		has_roe_authorization: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), //1b

		can_be_bounded: _emberData.default.attr('number'), //1c

		desired_effect: _emberData.default.attr('number'), //1d
		method: _emberData.default.attr('number'), //1e

		casualty_threshold: _emberData.default.attr('number'), //2
		expected_casualties: _emberData.default.attr('number'), //3

		has_possible_negative_sentiment: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), //4
		probable_negative_sentiment: _emberData.default.attr('number'), //5

		activities: _emberData.default.attr({ defaultValue() {
				return [];
			} }), //6

		degree_difficulty: _emberData.default.attr('number'), //7
		degree_resources: _emberData.default.attr('number'), //8

		part_of_io_campaign: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), //9
		degree_dependence_on_io: _emberData.default.attr('number'), //10

		tactical_hazards_exist: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), //11
		tactical_hazards_present_threat: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), //12
		degree_threat_tactical_hazards: _emberData.default.attr('number'), //13

		will_employ_mitigations: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), //14
		degree_likelihood_mitigations_effective: _emberData.default.attr('number'), //15
		degree_mitigations_effective: _emberData.default.attr('number'), //16

		macro_analysis: _emberData.default.attr({ defaultValue() {
				var pmesii = ['political', 'military', 'economic', 'social', 'information', 'infrastructure', 'physical', 'time'];
				var ascope = ['areas', 'structures', 'capabilities', 'organizations', 'people', 'events'];

				var value = {};
				pmesii.forEach(function (x) {
					ascope.forEach(function (y) {
						value[x + '_' + y] = { has_response: false, score: null, not_applicable: false, description: '' };
					});
				});
				return value;
			}
		}),

		micro_analysis: _emberData.default.attr({ defaultValue() {
				var dimensions = ['cognitive', 'affective', 'motivational'];

				var value = {};
				dimensions.forEach(function (x) {
					value[x] = { has_response: false, score: null, not_applicable: false, description: '' };
				});
				return value;
			}
		}),

		canProceed: Ember.computed('has_positive_id', 'has_roe_authorization', function () {
			return this.get('has_positive_id') && this.get('has_roe_authorization');
		}),

		serial_1c: Ember.computed.oneWay('can_be_bounded'),
		serial_1d: Ember.computed.oneWay('desired_effect'),
		serial_1e: Ember.computed.oneWay('method'),
		serial_3: Ember.computed('casualty_threshold', 'expected_casualties', function () {
			var ct = this.get('casualty_threshold');
			var ec = this.get('expected_casualties');
			if (ct === null || ec === null) return false;
			if (ec > ct) return 10;
			if (ec == ct) return 5;
			return 1;
		}),
		serial_5: Ember.computed.oneWay('probable_negative_sentiment'),
		serial_7: Ember.computed.oneWay('degree_difficulty'),
		serial_8: Ember.computed.oneWay('degree_resources'),
		serial_10: Ember.computed.oneWay('degree_dependence_on_io'),
		serial_13: Ember.computed.oneWay('degree_threat_tactical_hazards'),
		serial_15: Ember.computed.oneWay('degree_likelihood_mitigations_effective'),
		serial_16: Ember.computed.oneWay('degree_mitigations_effective'),

		score_step1: Ember.computed('modified', function () {
			// 1c, 1d, 1e, 3, 5, 7, 8, 10, 13, 15, 16
			var self = this;
			var values = ['1c', '1d', '1e', '3', '5', '7', '8', '10', '13', '15', '16'].map(function (item) {

				return self.get('serial_' + item);
			}).filter(function (item) {

				if (item === null) return false;
				return true;
			});
			var n = values.length;
			var sum = values.reduce(function (prev, current, list) {
				return prev + current;
			}, 0);
			return sum / n;
		}),

		score_step2a: Ember.computed('modified', function () {
			var macro = this.get('macro_analysis');
			var values = [];
			var completed = true;
			for (var key in macro) {
				completed = completed && macro[key].has_response;
				if (macro[key].has_response && !macro[key].not_applicable) values.push(macro[key].score);
			};
			if (!completed) return false;
			var n = values.length;
			var sum = values.reduce(function (prev, current, list) {
				return prev + current;
			}, 0);
			return sum / 48;
		}),

		score_step2b: Ember.computed('modified', function () {
			var micro = this.get('micro_analysis');
			var values = [];
			var completed = true;
			for (var key in micro) {
				completed = completed && micro[key].has_response;
				if (micro[key].has_response && !micro[key].not_applicable) values.push(micro[key].score);
			};
			if (!completed) return false;
			var sum = values.reduce(function (prev, current, list) {
				return prev + current;
			}, 0);
			return sum / values.length;
		}),

		risk_score: Ember.computed('score_step1', 'score_step2a', 'score_step2b', function () {

			var step_1 = this.get('score_step1');
			var step_2a = this.get('score_step2a');
			var step_2b = this.get('score_step2b');

			if (step_1 === false || step_2a === false || step_2b === false) return false;

			return Math.round((step_1 + step_2a + step_2b) / 3);
		})

	});
});