define('lazarus/router', ['exports', 'lazarus/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('assessments', function () {
      this.route('edit', { path: 'edit/:id_target/:id_conop' });
      this.route('brief', { path: 'brief/:id_target/:id_conop' });
    });
    this.route('about');
  });

  exports.default = Router;
});