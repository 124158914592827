define('lazarus/adapters/application', ['exports', 'ember-pouch'], function (exports, _emberPouch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { assert, isEmpty } = Ember;

  exports.default = _emberPouch.Adapter.extend({
    init() {
      this._super(...arguments);
      this.set('db', this.get('db'));
    }
  });
});