define('lazarus/pods/index/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		mode: 'alpha',
		user: Ember.inject.service(),

		sortedAssessments: Ember.computed('model', 'mode', function () {
			var mode = this.get('mode');
			if (mode == 'alpha') return this.get('model');

			if (mode == 'date') return this.get('model').sortBy('modified').reverse();

			if (mode == 'risk') return this.get('model').sortBy('risk_score').reverse();
		}),

		sortedTargets: Ember.computed('model.[]', 'mode', function () {

			var mode = this.get('mode');
			if (mode == 'alpha') return this.get('model').sortBy('name');

			if (mode == 'date') return this.get('model').sortBy('target.modified').reverse();

			if (mode == 'risk') return this.get('model').sortBy('risk_score').reverse();
		}),

		actions: {

			agreeToTerms() {
				this.get('user.activeUser').set('has_agreed_to_terms', true);
				this.get('user.activeUser').save();
			},

			setMode(which) {
				this.set('mode', which);
			},

			newTarget() {
				var self = this;

				return this.store.createRecord('target', { name: 'Target', analyst: this.get('user.activeUser') }).save().then(function (target) {
					var conop = self.store.createRecord('conop');
					conop.set('target', target);
					conop.save().then(function () {
						self.transitionToRoute('assessments.edit', conop);
					});
				});
			},

			newCONOP(target) {
				var self = this;

				var conop = self.store.createRecord('conop');
				conop.set('target', target);
				conop.save().then(function () {
					self.transitionToRoute('assessments.edit', conop);
				});
			},

			deleteTarget(which) {
				var self = this;
				this.store.query('conop', { filter: { target: which.get('id') } }).then(function (docs) {
					if (docs.get('length') > 0) {
						Ember.RSVP.all(docs.map(function (doc) {
							return doc.destroyRecord();
						})).then(function () {
							which.destroyRecord();
						});
					} else {
						which.destroyRecord();
					}
				});
			}
		}

	});
});