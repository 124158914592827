define('lazarus/models/target', ['exports', 'ember-pouch/model', 'ember-data'], function (exports, _model, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const {
		attr,
		hasMany,
		belongsTo
	} = _emberData.default;

	exports.default = _model.default.extend({

		analyst: _emberData.default.belongsTo('user'), // added in 1.1.0

		name: _emberData.default.attr('string'),
		category: _emberData.default.attr('string'), // added in 1.1.0
		target_value: _emberData.default.attr('string'), // added in 1.1.0
		target_function: _emberData.default.attr('string'), // added in 1.1.0

		conops: _emberData.default.hasMany('conop'), // added in 1.1.0

		created: _emberData.default.attr('date', { defaultValue() {
				return new Date();
			} }),
		modified: _emberData.default.attr('date'),

		is_hvt: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), // added in 1.2.0
		is_hpt: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), // added in 1.2.0
		is_tst: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), // added in 1.2.0

		rationale_hvt: _emberData.default.attr('string'), // added in 1.2.0
		rationale_hpt: _emberData.default.attr('string'), // added in 1.2.0
		rationale_tst: _emberData.default.attr('string'), // added in 1.2.0

		has_positive_id: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), //1a
		has_roe_authorization: _emberData.default.attr('boolean', { allowNull: true, defaultValue() {
				return null;
			} }), //1b

		can_be_bounded: _emberData.default.attr('number'), //1c

		canProceed: Ember.computed('is_hvt', 'is_hpt', 'is_tst', 'has_positive_id', 'has_roe_authorization', function () {
			return this.get('is_hvt') && this.get('is_hpt') && this.get('is_tst') && this.get('has_positive_id') && this.get('has_roe_authorization');
		})

	});
});