define('lazarus/components/attach-tooltip', ['exports', 'ember-attacher/components/attach-tooltip'], function (exports, _attachTooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _attachTooltip.default;
    }
  });
});