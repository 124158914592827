define('lazarus/pods/assessments/brief/controller', ['exports', 'lazarus/risk_scales'], function (exports, _risk_scales) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		risk_scales: _risk_scales.default,

		actions: {

			promptDelete() {
				this.set('isShowingMenu', false);
				this.set('isPromptingDelete', true);
			},

			remove() {
				var self = this;
				this.get('model').destroyRecord().then(function () {
					self.transitionToRoute('index');
				});
			}

		}
	});
});