define('lazarus/pods/assessments/edit/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		model(params) {
			return this.store.findRecord('conop', params.id_conop).then(function (conop) {
				return conop.get('target').then(function (target) {
					return conop;
				});
			});
		},

		setupController(controller, model) {
			this._super(...arguments);
			controller.set('currentSection', 0);
			controller.set('currentSlide', 0);
			controller.set('isPromptingDeleteCONOP', false);
			controller.set('isPromptingDeleteTarget', false);
			controller.set('isShowingMenu', false);
			Ember.run.bind(controller, controller.get('initializeSlideTracker'))();

			if (model.get('target.name') && model.get('target.category') && model.get('target.canProceed') && model.get('target.can_be_bounded')) {
				controller.send('goToSlide', 1, 0);
			}
		}

	});
});