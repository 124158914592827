define('lazarus/micro_metadata', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {

		cognitive: {
			display: "the cognitive state of unintended target audiences",
			eg: ''
		},
		affective: {
			display: "the affective/emotional state of unintended target audiences",
			eg: ''
		},
		motivational: {
			display: "the motivational state of unintended target audiences",
			eg: ''
		}

	};
});