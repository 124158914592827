define('lazarus/questions', ['exports', 'lazarus/macro_metadata', 'lazarus/micro_metadata'], function (exports, _macro_metadata, _micro_metadata) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var macro_lookup = _macro_metadata.default;
	var micro_lookup = _micro_metadata.default;

	exports.default = {

		"0a": {
			kind: 'boolean',
			question: "Is this a high-value target (HVT)?",
			popover: "A <b>high-value target</b> is defined as: <i>a target identified as critical to an actor or organization for achieving its goal</i>. Successfully influencing such a target will seriously hamper or support the actor or organization. They are determined by the value they offer to the actor or organization to which they belong.",
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"0b": {
			kind: 'boolean',
			question: "Is this a high-payoff target (HPT)?",
			popover: "A <b>high pay-off target</b> is defined as: <i>a high value target, the successful influencing of which will offer a disproportionate advantage to friendly forces</i>. <span class='text-muted'>Note: High pay-off targets are determined by the value they offer to friendly forces rather than other actors.</span>",
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"0c": {
			kind: 'boolean',
			question: "Is this a time-sensitive target (TST)?",
			popover: "TSTs are <b>those targets requiring an immediate response</b> because they pose (or will soon pose) a danger to friendly forces or are highly lucrative, fleeting targets of opportunity whose successful engagement is of high priority to achieve campaign or operational objectives.",
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},

		"1a": {
			kind: 'boolean',
			question: "Is there positive ID?",
			popover: "Positive ID refers to whether you can confirm you have identified the target as such. <hr/> For example: <ul><li>Is this an authorized target audience?</li><li>Do we know which audience(s) we are engaging with this activity?</li><li>Do we know how to distinguish this target from the general population?</li></ul>",
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"1b": {
			kind: 'boolean',
			question: 'Is the target or target audience authorized by ROE?',
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"1c": {
			kind: 'boolean',
			question: 'Can the target or target audience be bounded or compartmentalized to help limited or contain the informational activity?',
			answer: [{ label: 'Yes', value: 1 }, { label: 'No', value: 10 }]
		},
		"1d": {
			kind: 'multiple-choice',
			question: 'What is the effect to be generated by the informational activity?',
			answer: [{
				label: 'Behaviour reinforcing',
				value: 1,
				description: 'An activity that reinforces or enhances the current behaviour of the target or target audience.'
			}, {
				label: 'Behaviour modification',
				value: 5,
				description: 'An activity that slightly modifies or alters the current behaviour of the target or target audience.'
			}, {
				label: 'Behaviour change',
				value: 10,
				description: 'An activity that significantly modifies or alters the current behaviour of the target or target audience.'
			}]
		},
		"1e": {
			kind: 'multiple-choice',
			question: 'What mechanism will be used to achieve the intended effect?',
			answer: [{
				label: 'Engagement',
				value: 1,
				description: 'Engagement is defined as a change in target behaviour through consultation, participation, involvement and commitment of partners, allies, local agencies, populations in support of mission objectives. Engagement is aimed at changing behaviours, attitudes and perceptions and is characterized by consistent, culturally sensitive, credible, adaptive balanced and pragmatic relations and discourse.'
			}, {
				label: 'Influence',
				value: 5,
				description: 'Influence is defined as an act, ideally unknown or unrecognized by the target, that elicits a voluntary change in target behaviour through the intentional manipulation and exploitation of psychological, social-cultural, and/or physical factors that shape attitude and/or intent.'
			}, {
				label: 'Coercion',
				value: 10,
				description: 'Coercion is defined as an act that elicits a forced change in target behaviour, typically achieved through the threat of punishment or the imposition of punitive measures.'
			}]
		},
		"2": {
			kind: 'numeric',
			question: 'What is the Casualty Threshold (CT) for the mission, as a first order effect?',
			popover: 'Casualty threshold is a predetermined number of casualties deemed to be the upper acceptable limit for a strike. <hr/> This is <b>not</b> a casualty model as developed in the CDE process.'
		},
		"3": {
			kind: 'numeric',
			question: 'What is the expected number of casualties of the proposed activity, as a first order effect? ',
			popover: 'This is the expected (i.e., reasonably certain) number of casualties that will occur as a direct result of the proposed activity. This number is <b>independent of enemy action</b>.<hr/> This is <b>not</b> a casualty model as developed in the CDE process.'
		},
		"3a": {
			kind: 'boolean',
			question: "Are human shields in the collateral hazard area of the informational weapon/activity?",
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"4": {
			kind: 'boolean',
			question: "Could the informational activity generate negative public sentiment that will undermine the credibility of the mission?",
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"5": {
			kind: 'likert-scale',
			question: 'What is the likelihood the adversary will generate negative second or third order effects in response to the informational activity?',
			answer: [{ value: 1, label: 'Extremely unlikely' }, { value: 2, label: 'Highly unlikely' }, { value: 3, label: 'Moderately unlikely' }, { value: 4, label: 'Somewhat unlikely' }, { value: 5, label: 'Neutral, but leaning towards unlikely' }, { value: 6, label: 'Neutral, but leaning towards likely' }, { value: 7, label: 'Somewhat likely' }, { value: 8, label: 'Moderately likely' }, { value: 9, label: 'Highly likely' }, { value: 10, label: 'Extremely likely' }]
		},
		"6": {
			kind: 'multiple-select',
			question: 'What capabilities will be utilised to generate the effect?',
			answer: [{ label: 'PSYOPS', value: 'PSYOPS' }, { label: 'CIMIC', value: 'CIMIC' }, { label: 'PA', value: 'PA' }, { label: 'KLE', value: 'KLE' }, { label: 'PPP', value: 'PPP' }, { label: 'MILDEC', value: 'MILDEC' }, { label: 'PHYSICAL DESTRUCTION', value: 'Physical Destruction' }, { label: 'ELECTRONIC WARFARE', value: 'Electronic Warfare' }, { label: 'COMPUTER NETWORK ATTACK', value: 'Computer Network Attack' }, { label: 'STO / SPECAP', value: 'STO / SPECAP' }, { label: 'Other...', value: 'OTHER' }]
		},
		"6a": {
			kind: 'multiple-select',
			question: 'Which non-CAF capabilities assets will be utilized to generate the effect?',
			answer: [{ label: 'OGDs', value: 'OGDs' }, { label: 'IOs / NGOs', value: 'IOs / NGOs' }, { label: 'Proxies or third parties', value: 'Proxies or third parties' }]
		},
		"6b": {
			kind: 'likert-scale',
			question: 'What is the likelihood of negative effects (first / second order) to either CAF or the organizations should CAF involvement be discovered?',
			answer: [{ value: 1, label: 'No harm' }, { value: 2, label: 'No harm' }, { value: 3, label: 'Some harm' }, { value: 4, label: 'Some harm' }, { value: 5, label: 'Some harm' }, { value: 6, label: 'Moderate harm' }, { value: 7, label: 'Moderate harm' }, { value: 8, label: 'Moderate harm' }, { value: 9, label: 'Significant harm' }, { value: 10, label: 'Significant harm' }]
		},
		"7": {
			kind: 'likert-scale',
			question: 'What is the degree of difficulty of this informational activity? ',
			answer: [{ value: 1, label: 'Very easy' }, { value: 2, label: 'Easy' }, { value: 3, label: 'Moderately easy' }, { value: 4, label: 'Somewhat easy' }, { value: 5, label: 'Neutral, but leaning towards easy' }, { value: 6, label: 'Neutral, but leaning towards difficult' }, { value: 7, label: 'Somewhat difficult' }, { value: 8, label: 'Moderately difficult' }, { value: 9, label: 'Difficult' }, { value: 10, label: 'Very difficult' }]
		},
		"8": {
			kind: 'likert-scale',
			question: 'What amount of resources does this informational activity require?',
			answer: [{ value: 1, label: 'No resources' }, { value: 2, label: 'No resources' }, { value: 3, label: 'Some resources' }, { value: 4, label: 'Some resources' }, { value: 5, label: 'Some resources' }, { value: 6, label: 'A moderate amount of resources' }, { value: 7, label: 'A moderate amount of resources' }, { value: 8, label: 'A moderate amount of resources' }, { value: 9, label: 'A significant amount of resources' }, { value: 10, label: 'A significant amount of resources' }]
		},
		"9": {
			kind: 'boolean',
			question: 'Is this activity a part of an informational campaign?',
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"10": {
			kind: 'likert-scale',
			question: 'To what degree does this activity rely on other informational activities for success?',
			answer: [{ value: 1, label: 'Slightly reliant' }, { value: 2, label: 'Slightly reliant' }, { value: 3, label: 'Somewhat reliant' }, { value: 4, label: 'Somewhat reliant' }, { value: 5, label: 'Somewhat reliant' }, { value: 6, label: 'Moderately reliant' }, { value: 7, label: 'Moderately reliant' }, { value: 8, label: 'Moderately reliant' }, { value: 9, label: 'Extremely reliant' }, { value: 10, label: 'Extremely reliant' }]
		},
		"11": {
			kind: 'boolean',
			question: 'Does the adversary have the means to generate negative effects against the target?',
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"12": {
			kind: 'boolean',
			question: 'Does the target or targeting CONOPS carry a risk of negative secondary effects from the adversary?',
			popover: "<p>For example:</p><ul><li>EN jamming capability could deny vital information to civilians. <li>EN or other threat actors could harm or kill civilians due to our target CONOPS. <li>EN intelligence capability could detect deception or source attribution. <li>EN may target civilians in reaction to this target or CONOPS. <li>Target CONOPS may cause civilian unrest or mass movements.</ul>",
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"13": {
			kind: 'likert-scale',
			question: 'What is the likelihood the adversarial tactical will generate or amplify a negative secondary effects?',
			answer: [{ value: 1, label: 'Extremely unlikely' }, { value: 2, label: 'Highly unlikely' }, { value: 3, label: 'Moderately unlikely' }, { value: 4, label: 'Somewhat unlikely' }, { value: 5, label: 'Neutral, but leaning towards unlikely' }, { value: 6, label: 'Neutral, but leaning towards likely' }, { value: 7, label: 'Somewhat likely' }, { value: 8, label: 'Moderately likely' }, { value: 9, label: 'Highly likely' }, { value: 10, label: 'Extremely likely' }]
		},
		"14": {
			kind: 'boolean',
			question: 'Will mitigations be employed to reduce the negative secondary effects generated by the adversary?',
			answer: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
		},
		"15": {
			kind: 'likert-scale',
			question: 'What is the likelihood the mitigations will reduce the negative effect?',
			answer: [{ value: 10, className: 'risk-bg-1', label: 'Extremely likely' }, { value: 9, className: 'risk-bg-2', label: 'Highly likely' }, { value: 8, className: 'risk-bg-3', label: 'Moderately likely' }, { value: 7, className: 'risk-bg-4', label: 'Somewhat likely' }, { value: 6, className: 'risk-bg-5', label: 'Neutral, but leaning towards likely' }, { value: 5, className: 'risk-bg-6', label: 'Neutral, but leaning towards unlikely' }, { value: 4, className: 'risk-bg-7', label: 'Somewhat unlikely' }, { value: 3, className: 'risk-bg-8', label: 'Moderately unlikely' }, { value: 2, className: 'risk-bg-9', label: 'Highly unlikely' }, { value: 1, className: 'risk-bg-10', label: 'Extremely unlikely' }]
		},
		"16": {
			kind: 'likert-scale',
			question: 'To what degree will the mitigations will reduce the negative effect?',
			answer: [{ value: 10, label: 'No reduction' }, { value: 9, label: 'Minimal reduction' }, { value: 8, label: 'Minimal reduction' }, { value: 7, label: 'Slight reduction' }, { value: 6, label: 'Slight reduction' }, { value: 5, label: 'Marginal reduction' }, { value: 4, label: 'Marginal reduction' }, { value: 3, label: 'Moderate reduction' }, { value: 2, label: 'Moderate reduction' }, { value: 1, label: 'Significant reduction' }]
		},
		"MACRO": function () {
			var pmesii = ['political', 'military', 'economic', 'social', 'information', 'infrastructure', 'physical', 'time'];
			var ascope = ['areas', 'structures', 'capabilities', 'organizations', 'people', 'events'];

			var value = {};
			pmesii.forEach(function (x) {
				ascope.forEach(function (y) {
					value[x + '_' + y] = {
						kind: 'likert-scale',
						question: 'What is the expected effect on ' + macro_lookup[x + '_' + y].display + '?',
						description: macro_lookup[x + '_' + y].eg,
						answer: [{ value: 2, label: 'Extremely Limited Negative Effect' }, { value: 4, label: 'Minimal Negative Effect' }, { value: 6, label: 'Moderate Negative Effect' }, { value: 8, label: 'Significant Negative Effect' }, { value: 10, label: 'Extreme Negative Effect' }]
					};
				});
			});
			return value;
		}(),
		"MICRO": function () {
			var dimensions = ['cognitive', 'affective', 'motivational'];

			var value = {};
			dimensions.forEach(function (x) {
				value[x] = {
					kind: 'likert-scale',
					question: 'What is the expected effect on ' + micro_lookup[x].display + '?',
					answer: [{ value: 2, label: 'Extremely Limited Negative Effect' }, { value: 4, label: 'Minimal Negative Effect' }, { value: 6, label: 'Moderate Negative Effect' }, { value: 8, label: 'Significant Negative Effect' }, { value: 10, label: 'Extreme Negative Effect' }]
				};
			});
			return value;
		}()
	};
});