define('lazarus/pods/components/micro-prompt/component', ['exports', 'lazarus/questions'], function (exports, _questions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		questions: _questions.default,

		score: null,
		not_applicable: false,
		description: '',

		updateScore: Ember.observer('score', function () {

			if (this.get('score') > 0) {
				this.set('model.' + this.get('key') + '.score', this.get('score'));
				this.set('model.' + this.get('key') + '.has_response', true);
			}

			this.get('onChange')(this.get('model'));
		}),

		updateDescription: Ember.observer('description', function () {
			this.set('model.' + this.get('key') + '.description', this.get('description'));
			this.get('onChange')(this.get('model'));
		}),

		didInsertElement() {
			var key = this.get('key');
			this.set('score', this.get('model.' + key + '.score'));
			this.set('not_applicable', this.get('model.' + key + '.not_applicable'));
			this.set('description', this.get('model.' + key + '.description'));
		},

		actions: {
			toggleNA() {
				this.toggleProperty('not_applicable');

				if (this.get('not_applicable')) {
					// reset all values
					this.set('model.' + this.get('key') + '.score', 0);
					this.set('model.' + this.get('key') + '.description', '');
					this.set('model.' + this.get('key') + '.has_response', true);
				} else {
					this.set('model.' + this.get('key') + '.score', null);
					this.set('model.' + this.get('key') + '.has_response', false);
					this.set('model.' + this.get('key') + '.description', '');
					this.set('score', null);
				}
				this.set('model.' + this.get('key') + '.not_applicable', this.get('not_applicable'));
				this.get('onChange')(this.get('model'));
			}
		}

	});
});