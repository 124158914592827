define('lazarus/services/migrations', ['exports', 'lazarus/config/environment', 'lazarus/user_helpers'], function (exports, _environment, _user_helpers) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		store: Ember.inject.service(),
		config: _environment.default,

		// Migrations should be idempotent, as they may be run in more than one upgrade path
		// Write migration scripts with the expectation that they may run more than once,
		//    so, avoid saving database documents unnecessarily.

		migrate: {

			'1.1.0': function () {
				// run when upgrading to 1.1.0

				console.log('running migration for version 1.1.0');

				var self = this;
				var new_users = [];
				var new_targets = [];
				var new_conops = [];

				var cancel = false;

				var unique_users = {};

				return this.get('store').findAll('assessment').then(function (assessments) {

					assessments.forEach(function (assessment) {

						if (assessment.get('archived')) {
							cancel = true;return;
						}

						if (assessment.get('analyst') === null) assessment.set('analyst', 'Default User');
						unique_users[assessment.get('analyst')] = true;
					});

					// assessments have already been migrated. don't perform the migration again.
					if (cancel) {
						console.log('Data for version 1.1.0 has already been migrated');return assessments;
					}

					return Ember.RSVP.allSettled(Object.keys(unique_users).map(function (username) {
						return self.get('store').createRecord('user', { name: username, active: username == 'Default User' ? true : false }).save();
					})).then(function () {

						return self.get('store').findAll('user').then(function (users) {

							var targetKeys = ['name', 'created', 'modified', 'has_positive_id', 'has_roe_authorization', 'can_be_bounded'];
							var conopKeys = ['desired_effect', 'method', 'casualty_threshold', 'expected_casualties', 'has_possible_negative_sentiment', 'probable_negative_sentiment', 'activities', 'degree_difficulty', 'degree_resources', 'part_of_io_campaign', 'degree_dependence_on_io', 'tactical_hazards_exist', 'tactical_hazards_present_threat', 'degree_threat_tactical_hazards', 'will_employ_mitigations', 'degree_likelihood_mitigations_effective', 'degree_mitigations_effective', 'macro_analysis', 'micro_analysis'];

							assessments.forEach(function (assessment) {

								var doc = JSON.parse(JSON.stringify(assessment));
								var conop = self.get('store').createRecord('conop');
								conopKeys.forEach(function (key) {
									conop.set(key, doc[key]);
								});
								var tgt = self.get('store').createRecord('target');
								targetKeys.forEach(function (key) {
									tgt.set(key, doc[key]);
								});
								tgt.set('analyst', users.filterBy('name', assessment.get('analyst')).get('firstObject'));

								new_targets.push(tgt.save().then(function (saved_target) {
									conop.set('target', saved_target);
									new_conops.push(conop.save());
								}));

								assessment.set('archived', true);
								assessment.save();
							});

							return Ember.RSVP.allSettled(new_targets).catch(function (err) {
								console.log(err);
							});
						});
					});
				});

				//return Ember.RSVP.Promise( function(resolve, reject){ resolve() })
			}

		},

		setup: function () {

			var self = this;

			var db = this.get('db');
			var isCordova = typeof cordova !== 'undefined';
			console.log(this.get('db'));

			var latest_version = null;

			return Ember.RSVP.allSettled([Ember.$.get("assets/VERSION.txt"), db.get('_local/version')]).then(function (responses) {

				console.log(responses);

				var state_hash = {
					content_exists: false
				};

				if (responses[0].state == 'rejected') latest_version = "1.0.0";else latest_version = responses[0].value.trim();

				if (responses[1].state == 'rejected') {
					state_hash.content_exists = false;
					state_hash['pouch_response'] = responses[1].reason;
				} else {
					state_hash.content_exists = true;
					state_hash['preloaded_doc'] = responses[1].value;
				}

				return state_hash;
			}).then(function (status) {

				console.log(status, latest_version);

				return new Ember.RSVP.Promise(function (resolve, reject) {

					var preloaded_doc = { _id: '_local/version' }; // this will be the local document we use to store the version number on the client

					if (status.content_exists) {

						if (latest_version == status.preloaded_doc['version_number']) {
							// there are no changes to import

							resolve();
							return;
						}
					}

					// if we've reached this line, then there are data migrations to perform

					if ('pouch_response' in status && status.pouch_response.name !== 'not_found') {

						reject();
					}

					if ('preloaded_doc' in status) {
						// this ensure we have the correct _rev key for an existing local document

						preloaded_doc = status.preloaded_doc;
					}
					preloaded_doc.version_number = latest_version;

					console.log("Performing migrations now.", latest_version);

					var perform_migration = function (version) {
						return Ember.run.bind(self, self.get('migrate')[version])();
					};

					var update_client_version = function () {
						db.put(preloaded_doc).then(function (result) {
							console.log('Migrations complete', result);
							resolve(); //or reject()...	
						});
					};

					if (latest_version == '1.0.0') {
						update_client_version();
					}
					if (latest_version == '1.1.0') {

						perform_migration('1.1.0').then(update_client_version);
					} else if (latest_version == "1.2.0") {

						perform_migration('1.1.0').then(function () {
							perform_migration('1.2.0');
						}).then(update_client_version);
					} else if (latest_version == "1.3.0") {

						perform_migration('1.1.0').then(function () {
							perform_migration('1.2.0');
						}).then(function () {
							perform_migration('1.3.0');
						}).then(update_client_version);
					}
				});
			});
		}
	});
});