define('lazarus/initializers/application', ['exports', 'pouchdb', 'lazarus/config/environment', 'npm:pouchdb-adapter-websql'], function (exports, _pouchdb, _environment, _npmPouchdbAdapterWebsql) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {

		initialize: function (application) {

			var db;

			if (_environment.default.use_websql) {
				_pouchdb.default.plugin(_npmPouchdbAdapterWebsql.default);
				db = new _pouchdb.default(_environment.default.emberPouch.localDb, { adapter: 'websql' });
			} else {
				db = new _pouchdb.default(_environment.default.emberPouch.localDb);
			}

			//console.log(config.use_websql, db.adapter);
			if (_environment.default.emberPouch.remoteDb) {
				let remoteDb = new _pouchdb.default(_environment.default.emberPouch.remoteDb);

				db.sync(remoteDb, {
					live: true,
					retry: true
				});
			}

			application.register('db:main', db, { instantiate: false });
			application.inject('route', 'db', 'db:main');
			application.inject('controller', 'db', 'db:main');
			application.inject('component', 'db', 'db:main');
			application.inject('service:migrations', 'db', 'db:main');
			application.inject('service:user', 'db', 'db:main');
			application.inject('adapter:application', 'db', 'db:main');

			application.register('config:main', Ember.copy(_environment.default, true), { instantiate: false });
			application.inject('route', 'config', 'config:main');
			application.inject('controller', 'config', 'config:main');
			application.inject('component', 'config', 'config:main');
			application.inject('service:migrations', 'config', 'config:main');

			application.inject('route', 'user', 'service:user');
			application.inject('controller', 'user', 'service:user');
			application.inject('component', 'user', 'service:user');

			if (typeof cordova !== 'undefined') {

				application.deferReadiness();

				document.addEventListener('deviceready', function () {

					application.advanceReadiness();
				}, false);
			}
		}
	};
});