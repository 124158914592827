define('lazarus/models/user', ['exports', 'ember-pouch/model', 'lazarus/user_helpers', 'ember-data'], function (exports, _model, _user_helpers, _emberData) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});


		const {
				attr,
				hasMany,
				belongsTo
		} = _emberData.default;

		exports.default = _model.default.extend({

				name: _emberData.default.attr('string', { defaultValue: "User" }),

				// used as the authentication credentials for this user's CouchDB database 
				// this has not been implementet yet, but we have the hash in place for when it is
				hash: _emberData.default.attr('string', { defaultValue: function () {
								return _user_helpers.default.generate_hash(15);
						} }),

				active: _emberData.default.attr('boolean', { defaultValue: false }),

				has_agreed_to_terms: _emberData.default.attr('boolean', { defaultValue: false })

		});
});