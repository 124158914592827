define("lazarus/pods/application/controller", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		currentRoute: Ember.computed("currentRouteName", function () {
			return this.get("currentRouteName").split(".").join("-");
		})

	});
});