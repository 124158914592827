define("lazarus/user_helpers", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {

		"generate_hash": function (plength) {

			var keylist = "abcdefghijklmnopqrstuvwxyz1234567890";
			var temp = '';
			for (var i = 0; i < plength; i++) temp += keylist.charAt(Math.floor(Math.random() * keylist.length));
			return temp;
		}
	};
});