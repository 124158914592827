define('lazarus/pods/components/questions/question-multiple-choice/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['question', "question-multiple-choice"],

		actions: {
			selectAnswer(index) {
				this.set('response', this.get('question.answer.' + index + '.value'));
			}
		}
	});
});