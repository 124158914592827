define('lazarus/transitions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {
		// Add your transitions here, like:
		//   this.transition(
		//     this.fromRoute('people.index'),
		//     this.toRoute('people.detail'),
		//     this.use('toLeft'),
		//     this.reverse('toRight')
		//   );

		this.transition(this.toRoute('index'), this.use('toRight', { duration: 500, easing: "easeOutQuart" }), this.reverse('toLeft', { duration: 500, easing: "easeOutQuart" }));

		this.transition(this.childOf(".assessment-list"), this.use('explode', {
			matchBy: 'data-id',
			use: ['fly-to', { duration: 300, easing: 'easeOutQuart' }]
		}));

		this.transition(this.toValue(function (toValue, fromValue) {
			if (toValue.type == "nav") {
				if (fromValue.section < toValue.section) return true;else {
					if (fromValue.section == toValue.section && fromValue.slide < toValue.slide) return true;
				}
			}
		}), this.use("toLeft", { easing: "easeOutExpo", duration: 400 }), this.reverse("toRight", { easing: "easeOutExpo", duration: 400 }));

		/*****************************************
           COMMON STATE TRANSITIONS
   *****************************************/
		this.transition(this.hasClass("slideUp"), this.toValue(true), this.use('toUp', { easing: "easeOutExpo", duration: 600 }), this.reverse('toDown', { easing: "easeOutExpo", duration: 600 }));

		this.transition(this.hasClass("slideDown"), this.toValue(true), this.use('toDown', { easing: "easeOutExpo", duration: 600 }), this.reverse('toUp', { easing: "easeOutExpo", duration: 600 }));

		this.transition(this.hasClass("slideLeft"), this.toValue(true), this.use('toRight', { easing: "easeOutExpo", duration: 600 }), this.reverse('toLeft', { easing: "easeOutExpo", duration: 600 }));

		this.transition(this.hasClass("crossFade"), this.toValue(true), this.use('crossFade', { easing: "easeOutExpo", duration: 600 }), this.reverse('crossFade', { easing: "easeOutExpo", duration: 600 }));

		this.transition(this.hasClass("toggleFade"), this.toValue(true), this.use('fade', { easing: "easeOutExpo", duration: 600 }));
	};
});