define('lazarus/pods/application/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		migrations: Ember.inject.service(),

		beforeModel() {

			var self = this;
			console.log('beforemodel');
			// See if there are any data migrations that need to be run

			return Ember.RSVP.all([self.get('migrations').setup()]).then(function () {

				console.log('Migration check is now complete');
			});
		},

		model() {

			var self = this;

			// Query the store for the user record with the 'active' flag set to true
			// If the result set is not empty, return that record as the model
			// if the result set is empty, initialize a new user, and return this record as a model
			//    set name: 'Default User', 'active' to true

			return self.user.initialize_active_user().then(function (user) {

				// if there is any arbitrary code to run now that we know the user, do it here.
				return user;
			}).catch(function (err) {
				console.log(JSON.stringify(err));
			});
		}

	});
});