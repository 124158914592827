define('lazarus/macro_metadata', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {

		political_areas: {
			display: "Political Areas",
			eg: 'District, Boundary, Party affiliation areas, tribal/religious sects’ areas'
		},
		political_structures: {
			display: "Political Structures",
			eg: "Political or advocacy building, offices and institutions"
		},
		political_capabilities: {
			display: "Political Capabilities",
			eg: "Political, religious and social leadership, as well as insurgent or enemy leadership, tribal, religious leaders"
		},
		political_organizations: {
			display: "Political Organizations",
			eg: "Political parties, advocacy groups, insurgent and enemy groups"
		},
		political_people: {
			display: "Political People",
			eg: "Formal and informal political and social leaders, community leaders, clan and tribal elders "
		},
		political_events: {
			display: "Political Events",
			eg: "Elections, council meeting and other political meetings, speeches and other political or politicized events"
		},

		military_areas: {
			display: "Military Areas",
			eg: "Groupings, force structure, area operations of regular and irregular forces"
		},
		military_structures: {
			display: "Military Structures",
			eg: "Police and military facilities, insurgent or enemy facilities"
		},
		military_capabilities: {
			display: "Military Capabilities",
			eg: "Police and military capacity and capability, as well as insurgent or enemy capability"
		},
		military_organizations: {
			display: "Military Organizations",
			eg: "Force structure and organization of military and police, as well as insurgent or enemy forces"
		},
		military_people: {
			display: "Military People",
			eg: "Leaders and leadership structure, powerbrokers and key influencers, including insurgent and enemy forces"
		},
		military_events: {
			display: "Military Events",
			eg: "Change of command, parades, key operations, formal and informal events (e.g., TGIT)"
		},

		economic_areas: {
			display: "Economic Areas",
			eg: "Markets, economic zones, trade routes, business zones"
		},
		economic_structures: {
			display: "Economic Structures",
			eg: "Shopping malls and businesses"
		},
		economic_capabilities: {
			display: "Economic Capabilities",
			eg: "Ability of banking and economic sector to withstand stressors, such as unemployment and other shocks"
		},
		economic_organizations: {
			display: "Economic Organizations",
			eg: "Local business or market associations "
		},
		economic_people: {
			display: "Economic People",
			eg: "Bank owners and managers, landowners, and other formal and informal economic leaders"
		},
		economic_events: {
			display: "Economic Events",
			eg: "Business sector openings and closings, market events, annual collective vacation for business / manufacturing sector"
		},

		social_areas: {
			display: "Social Areas",
			eg: "Cultural locations, sports arenas, religious sites, other gathering places"
		},
		social_structures: {
			display: "Social Structures",
			eg: "Religious structures, event venues, popular buildings, including entertainment facilities (clubs, etc.)"
		},
		social_capabilities: {
			display: "Social Capabilities",
			eg: "Strength and ability of key family, tribal, clan or other social and religious networks and affiliations"
		},
		social_organizations: {
			display: "Social Organizations",
			eg: "Family, clan, tribal, sport, educational and other identity affiliations"
		},
		social_people: {
			display: "Social People",
			eg: "Religious and non-religious leaders, influential families, and other charismatic community members"
		},
		social_events: {
			display: "Social Events",
			eg: "Regular and special religious and social events, including weddings, births, and community festivals"
		},

		information_areas: {
			display: "Information Areas",
			eg: "Formal and informal news media and information relays, including TV, radio and Internet provider areas, local gossip"
		},
		information_structures: {
			display: "Information Structures",
			eg: "Cell, radio, and TV towers / broadcast facilities, informal and formal networks"
		},
		information_capabilities: {
			display: "Information Capabilities",
			eg: "Literacy rates, availability of and access to media, media penetration rates, informal and formal information networks"
		},
		information_organizations: {
			display: "Information Organizations",
			eg: "Formal and informal information powerbrokers and networks"
		},
		information_people: {
			display: "Information People",
			eg: "Formal and informal media and information network owners and managers"
		},
		information_events: {
			display: "Information Events",
			eg: "Publishing dates or deadlines, information campaigns, special events / launches"
		},

		infrastructure_areas: {
			display: "Infrastructure Areas",
			eg: "Critical infrastructure, including power generations, dams, waterways, main roads, hospitals, schools, etc."
		},
		infrastructure_structures: {
			display: "Infrastructure Structures",
			eg: "Roads, bridges, electric lines and generations facilities, public works buildings"
		},
		infrastructure_capabilities: {
			display: "Infrastructure Capabilities",
			eg: "Ability of government (formal or informal) to maintain roads and critical infrastructure"
		},
		infrastructure_organizations: {
			display: "Infrastructure Organizations",
			eg: "Regional and municipal government, informal governance structures, constructions companies"
		},
		infrastructure_people: {
			display: "Infrastructure People",
			eg: "Builders and construction company owners, power and works management and owners"
		},
		infrastructure_events: {
			display: "Infrastructure Events",
			eg: "Opening and closing of key infrastructure, key construction"
		},

		physical_areas: {
			display: "Physical Areas",
			eg: "Naturally bounded geographic area due to mountains, forests, water, or other change in terrain "
		},
		physical_structures: {
			display: "Physical Structures",
			eg: "Dominant natural and manmade structures"
		},
		physical_capabilities: {
			display: "Environment Capabilities",
			eg: "Farm and natural resource production, mineral wealth"
		},
		physical_organizations: {
			display: "Environment Organization",
			eg: "Urban and rural planning, social diversity and demographics, areas of stratification"
		},
		physical_people: {
			display: "Physical Environment & People",
			eg: "Farm owners and landowners, relationship between families and geographic area"
		},
		physical_events: {
			display: "Environmental Events",
			eg: "Events linked to the natural or manmade environment"
		},

		time_areas: {
			display: "Time Areas",
			eg: "Time zone, but also local understanding and appreciation for time"
		},
		time_structures: {
			display: "Time & Structures",
			eg: "Facilities that are used at a specific time of day or year, such as religious buildings or meeting places"
		},
		time_capabilities: {
			display: "Time & Capabilities",
			eg: "Ability of leaders and workers to perform on time"
		},
		time_organizations: {
			display: "Time & Organization",
			eg: "Event planning, event structure, linear versus non-linear"
		},
		time_people: {
			display: "Time & People",
			eg: "Leadership or organizers of key events linked to the calendar, events that commemorate a person or group of people "
		},
		time_events: {
			display: "Time & Events",
			eg: "Dates and time for events, relevance to the calendar"
		}

	};
});