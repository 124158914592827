define('lazarus/pods/components/fields/field-number/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		decimal: false,

		min: undefined,
		max: undefined

	});
});