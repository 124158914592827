define('lazarus/pods/assessments/brief/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		model(params) {
			return this.store.findRecord('conop', params.id_conop).then(function (doc) {
				return doc;
			});
		},

		setupController(controller, model) {
			this._super(...arguments);
			controller.set('isPromptingDelete', false);
			controller.set('isShowingMenu', false);
		}
	});
});