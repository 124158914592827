define('lazarus/pods/assessments/edit/controller', ['exports', 'lazarus/macro_metadata', 'lazarus/risk_scales', 'lazarus/questions'], function (exports, _macro_metadata, _risk_scales, _questions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		observer_timestamp: null,

		isShowingMenu: false,
		isPromptingDeleteCONOP: false,
		isPromptingDeleteTarget: false,

		currentSection: 0,
		currentSlide: 0,

		section_slide: Ember.computed('currentSection', 'currentSlide', function () {
			return {
				type: 'nav',
				section: this.get('currentSection'),
				slide: this.get('currentSlide')
			};
		}),

		isShowingMacroModal: false,
		currentPMESII: null,
		currentASCOPE: null,
		currentMacroResponse: { has_response: false, score: null, not_applicable: false },

		questions: _questions.default,
		macro_lookup: _macro_metadata.default,
		risk_scales: _risk_scales.default,

		slide_is_completed: [],

		sections: [{
			title: "Target information",
			slides: [{
				description: "Provide some boilerplate information about this target.",
				isCompleted() {
					return this.get('model.target.name');
				}
			}, {
				description: "",
				isCompleted() {
					console.log('calculating slide 2', this.get('model.target'), this.get('model.target.has_positive_id'), this.get('model.target.has_roe_authorization'));
					console.log(this.get('model.target.is_hvt') !== null);
					console.log(this.get('model.target.is_hpt') !== null);
					console.log(this.get('model.target.is_tst') !== null);
					return this.get('model.target.is_hvt') !== null && this.get('model.target.is_hpt') !== null && this.get('model.target.is_tst') !== null && this.get('model.target.has_positive_id') && this.get('model.target.has_roe_authorization');
				}
			}, {
				description: "",
				isCompleted() {
					console.log('calculating slide 3', this.get('model.target.can_be_bounded'));return this.get('model.target.can_be_bounded') !== null;
				}
			}]
		}, {
			title: "Initial Assessment",
			slides: [{
				description: "Some questions before we begin the estimation process:",
				isCompleted() {
					return this.get('model.title');
				}
			}, {
				description: "",
				isCompleted() {
					return this.get('model.desired_effect') !== null;
				}
			}, {
				description: "",
				isCompleted() {
					return this.get('model.method') !== null;
				}
			}, {
				description: "",
				isCompleted() {
					return this.get('model.casualty_threshold') !== null && this.get('model.expected_casualties') !== null && this.get('model.human_shields_in_area') !== null;
				}
			}, {
				description: "",
				isCompleted() {
					if (this.get('model.has_possible_negative_sentiment') === null) return false;
					if (this.get('model.has_possible_negative_sentiment') && !this.get('model.probable_negative_sentiment')) return false;
					return true;
				}
			}, {
				description: "",
				isCompleted() {
					if ((this.get('model.activities_non_caf').indexOf('IOs / NGOs') != -1 || this.get('model.activities_non_caf').indexOf('Proxies or third parties') != -1) && this.get('model.activities_risk_non_caf') === null) return false;

					// if the user cleared activities_non_caf of relevant capabilities, ensure this syncs
					if (!(this.get('model.activities_non_caf').indexOf('IOs / NGOs') != -1 || this.get('model.activities_non_caf').indexOf('Proxies or third parties') != -1)) {
						this.set('model.activities_risk_non_caf', null);
					}
					return this.get('model.degree_difficulty') !== null && this.get('model.degree_resources') !== null;
				}
			}, {
				description: "",
				isCompleted() {
					if (this.get('model.part_of_io_campaign') === null) return false;
					if (this.get('model.part_of_io_campaign') && !this.get('model.degree_dependence_on_io')) return false;
					return true;
				}
			}, {
				description: "",
				isCompleted() {
					if (this.get('model.tactical_hazards_exist') === null) return false;
					if (this.get('model.tactical_hazards_exist')) {
						if (this.get('model.tactical_hazards_present_threat') === null) return false;
						if (this.get('model.tactical_hazards_present_threat')) {
							if (this.get('model.degree_threat_tactical_hazards') === null) return false;
							if (this.get('model.will_employ_mitigations') === null) return false;
							if (this.get('model.will_employ_mitigations')) {
								if (this.get('model.degree_likelihood_mitigations_effective') === null) return false;
								if (this.get('model.degree_mitigations_effective') === null) return false;
							}
						}
					}
					return true;
				}
			}]
		}, {
			title: "Effects Analysis (Social & Physical Systems)",
			slides: [{
				description: "For each factor, identify and estimate the potential 1st, 2nd and 3rd (if possible) order effects the informational activity will have on the PMESII+PT/ASCOPE factor. Tap on a cell to get started.",
				isCompleted() {
					var pmesii = ['political', 'military', 'economic', 'social', 'information', 'infrastructure', 'physical', 'time'];
					var ascope = ['areas', 'structures', 'capabilities', 'organizations', 'people', 'events'];
					var value = this.get('model.macro_analysis');
					var completed = true;
					pmesii.forEach(function (x) {
						ascope.forEach(function (y) {
							completed = completed && value[x + '_' + y].has_response;
						});
					});
					return completed;
				}
			}]
		}, {
			title: "Psychological Effects (on unintended target audiences)",
			slides: [{
				description: "The following questions have to do with effects analysis on a micro level.",
				isCompleted() {
					var responses = this.get('model.micro_analysis');
					return responses.cognitive.has_response && responses.affective.has_response && responses.motivational.has_response;
				}
			}]
		}, {
			title: "Summary",
			slides: [{
				description: "Based on the information you've provided, your target has the following risk profile."
			}]
		}],

		totalSections: Ember.computed('sections', function () {
			return this.get('sections.length');
		}),

		currentSectionSlideCount: Ember.computed('sections', 'currentSection', function () {
			return this.get('sections')[this.get('currentSection')].slides.length;
		}),

		sectionTitle: Ember.computed('currentSection', function () {
			return this.get('sections')[this.get('currentSection')].title || "";
		}),

		slideTitle: Ember.computed('currentSection', 'currentSlide', function () {
			return this.get('sections')[this.get('currentSection')].slides[this.get('currentSlide')].title || "";
		}),

		slideDescription: Ember.computed('currentSection', 'currentSlide', function () {
			return this.get('sections')[this.get('currentSection')].slides[this.get('currentSlide')].description || "";
		}),

		checkSlideCompleted(section, slide) {
			if (!('isCompleted' in this.get('sections')[section].slides[slide])) return true;
			return Ember.run.bind(this, this.get('sections')[section].slides[slide].isCompleted)();
		},

		initializeSlideTracker() {
			var tracker = this.get('sections').map(function (item) {
				return {};
			});
			var section_cursor = 0;
			var slide_cursor = 0;
			var section = this.get('sections.length') - 1;
			var slide = this.get('sections')[section].slides.length - 1;

			while (section_cursor < section || section_cursor == section && slide_cursor < slide) {

				tracker[section_cursor.toString()][slide_cursor.toString()] = this.checkSlideCompleted(section_cursor, slide_cursor);

				if (section_cursor < section) {
					if (slide_cursor < this.get('sections')[section_cursor].slides.length - 1) slide_cursor++;else {
						slide_cursor = 0;
						section_cursor++;
					}
				} else {
					if (slide_cursor < slide) slide_cursor++;else {
						section_cursor++;
					}
				}
			}

			this.set('slide_is_completed', tracker);
		},

		slideCompletionTracker: Ember.observer('model', 'observer_timestamp', 'model.target', 'model.target.is_hvt', 'model.target.is_hpt', 'model.target.is_tst', 'model.target.has_positive_id', 'model.target.has_roe_authorization', 'model.target.can_be_bounded', 'model.title', 'model.desired_effect', 'model.method', 'model.has_possible_negative_sentiment', 'model.probable_negative_sentiment', 'model.casualty_threshold', 'model.expected_casualties', 'model.human_shields_in_area', 'model.activities_non_caf[]', 'model.activities_risk_non_caf', 'model.degree_difficulty', 'model.degree_resources', 'model.part_of_io_campaign', 'model.degree_dependence_on_io', 'model.tactical_hazards_exist', 'model.tactical_hazards_present_threat', 'model.degree_threat_tactical_hazards', 'model.will_employ_mitigations', 'model.degree_likelihood_mitigations_effective', 'model.degree_mitigations_effective', 'isShowingMacroModal', 'model.micro_analysis.cognitive.has_response', 'model.micro_analysis.affective.has_response', 'model.micro_analysis.motivational.has_response', function () {
			var tracker;
			if (this.get('slide_is_completed').length == 0) {
				Ember.run.bind(this, this.get('initializeSlideTracker'))();
			}
			tracker = JSON.parse(JSON.stringify(this.get('slide_is_completed')));
			tracker[this.get('currentSection').toString()][this.get('currentSlide').toString()] = this.checkSlideCompleted(this.get('currentSection'), this.get('currentSlide'));
			this.set('slide_is_completed', tracker);
		}),

		slideCompleted: Ember.computed('currentSection', 'currentSlide', 'slide_is_completed', function () {
			return this.get('slide_is_completed')[this.get('currentSection')][this.get('currentSlide')];
		}),

		canGoForward: Ember.computed('model.target.canProceed', 'currentSection', 'currentSlide', function () {

			//if(!this.get('model.target.canProceed')) return false;

			if (this.get('currentSlide') + 1 < this.get('currentSectionSlideCount')) return true;else if (this.get('currentSlide') + 1 == this.get('currentSectionSlideCount')) {
				if (this.get('currentSection') + 1 < this.get('totalSections')) return true;
			}
			return false;
		}),

		canGoBack: Ember.computed('currentSection', 'currentSlide', function () {

			if (this.get('currentSection') == 0 && this.get('currentSlide') - 1 < 0) return false;
			return true;
		}),

		onGoToLastSlide: Ember.observer('currentSection', 'currentSlide', function () {
			var lastSectionIndex = this.get('sections').length - 1;
			var lastSlideIndex = this.get('sections')[lastSectionIndex].slides.length - 1;

			if (this.get('currentSection') == lastSectionIndex && this.get('currentSlide') == lastSlideIndex) {
				console.log('updating!');
				this.set('model.modified', new Date());
			}
		}),

		actions: {

			save() {
				var self = this;

				if (this.get('model.hasDirtyAttributes') || this.get('model.target.hasDirtyAttributes')) {
					this.set('model.modified', new Date());
					this.set('model.target.modified', new Date());
					this.get('model').save().then(function () {
						self.get('model.target').then(function (target) {
							target.save().then(function () {
								self.transitionToRoute('index');
							});
						});
					});
				} else self.transitionToRoute('index');
			},

			promptDeleteCONOP() {
				this.set('isShowingMenu', false);
				this.set('isPromptingDeleteCONOP', true);
			},

			promptDeleteTarget() {
				this.set('isShowingMenu', false);
				this.set('isPromptingDeleteTarget', true);
			},

			removeTarget() {
				var self = this;
				this.set('isPromptingDeleteTarget', false);
				this.transitionToRoute('index');

				Ember.RSVP.all([this.store.query('conop', { filter: { target: this.get('model.target.id') } }), this.store.findRecord('target', this.get('model.target.id'))]).then(function (response) {

					Ember.run.later(function () {

						if (response[0].get('length') > 0) {

							Ember.RSVP.all(response[0].map(function (doc) {
								return doc.destroyRecord();
							})).then(function () {
								response[1].destroyRecord();
							});
						} else {
							response[1].destroyRecord();
						}
					}, 500);
				});
			},

			removeCONOP() {
				var self = this;
				var model = this.get('model');
				this.set('isPromptingDeleteCONOP', false);
				this.transitionToRoute('index');
				Ember.run.later(function () {
					model.destroyRecord();
				}, 500);
			},

			showMacroDetails(pmesii, ascope) {
				this.set('currentPMESII', pmesii);
				this.set('currentASCOPE', ascope);
				this.set('currentMacroResponse', this.get('model.macro_analysis.' + pmesii + '_' + ascope));
				this.set('isShowingMacroModal', true);
			},

			toggleCurrentMacroNA() {
				this.toggleProperty('currentMacroResponse.not_applicable');
				if (this.get('currentMacroResponse.not_applicable')) {
					this.set('currentMacroResponse.score', 0);
					this.set('currentMacroResponse.description', '');
				} else {
					this.set('currentMacroResponse.score', null);
					this.set('currentMacroResponse.has_response', false);
				}
			},

			// will assign n/a to all remaining null responses
			skipRemainingMacroTiles() {
				var self = this;
				var pmesii = ['political', 'military', 'economic', 'social', 'information', 'infrastructure', 'physical', 'time'];
				var ascope = ['areas', 'structures', 'capabilities', 'organizations', 'people', 'events'];
				var temp_macro = Ember.copy(this.get('model.macro_analysis'));

				pmesii.forEach(function (x) {
					ascope.forEach(function (y) {
						if (!temp_macro[x + '_' + y].has_response) {
							temp_macro[x + '_' + y] = { has_response: true, score: 0, not_applicable: true, description: '' };
						}
					});
				});

				this.set('model.macro_analysis', temp_macro);
				this.set('observer_timestamp', new Date());
			},

			hideMacroDetails() {

				var ascope = this.get('currentASCOPE');
				var pmesii = this.get('currentPMESII');

				var temp_macro = Ember.copy(this.get('model.macro_analysis'));

				var activeQuestion = Ember.copy(this.get('currentMacroResponse'));
				if (activeQuestion.score > 0 || activeQuestion.not_applicable) activeQuestion.has_response = true;

				temp_macro[pmesii + '_' + ascope] = activeQuestion;

				this.set('model.macro_analysis', temp_macro);

				this.set('currentPMESII', null);
				this.set('currentASCOPE', null);
				this.set('currentMacroResponse', null);
				this.set('isShowingMacroModal', false);
			},

			updateMicroResponse(value) {
				this.set('model.micro_analysis', Ember.copy(value));
			},

			goToSlide(section, slide) {

				//if(!this.get('model.target.canProceed')) return false;

				var can_skip_to_slide = true;

				var is_skipping_ahead = section > this.get('currentSection') || slide > this.get('currentSlide');

				if (is_skipping_ahead) {
					var section_cursor = this.get('currentSection');
					var slide_cursor = this.get('currentSlide');
					while (section_cursor < section || section_cursor == section && slide_cursor < slide) {

						can_skip_to_slide = can_skip_to_slide && this.checkSlideCompleted(section_cursor, slide_cursor);

						if (section_cursor < section) {
							if (slide_cursor < this.get('sections')[section_cursor].slides.length - 1) slide_cursor++;else {
								slide_cursor = 0;
								section_cursor++;
							}
						} else {
							if (slide_cursor < slide) slide_cursor++;else {
								section_cursor++;
							}
						}
					}
				}

				if (!can_skip_to_slide) return false;

				this.set('currentSlide', slide);
				this.set('currentSection', section);
			},

			goForward() {

				if (!this.get('canGoForward')) return;
				if (!this.checkSlideCompleted(this.get('currentSection'), this.get('currentSlide'))) return;
				if (this.get('currentSlide') + 1 < this.get('currentSectionSlideCount')) this.incrementProperty('currentSlide');else {
					this.set('currentSlide', 0);
					this.incrementProperty('currentSection');
				}
			},

			goBack() {
				if (!this.get('canGoBack')) return;
				if (this.get('currentSlide') - 1 >= 0) this.decrementProperty('currentSlide');else {

					this.decrementProperty('currentSection');
					this.set('currentSlide', this.get('currentSectionSlideCount') - 1);
				}
			}

		}

	});
});